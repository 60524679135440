import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import ProjectList from "../components/project-list"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title="Tao Zhang - UX Design and Research"
      keywords={[`portfolio`, `UX`, `design`, `research`]}
    />

    <div className="intro">
      <p className="hi">Hi, I'm Tao.</p>
      <p>
        I'm a UX designer who loves to solve complex design problems with a
        human-centered approach. I also have extensive experience of design
        research and accessibility. Currently I'm working on{" "}
        <a href="https://aws.amazon.com/products/developer-tools/">
          Developer Tools
        </a>{" "}
        at <a href="https://aws.amazon.com/">Amazon Web Services</a>.
      </p>
    </div>
    <ProjectList items={data.designs.edges} />
  </Layout>
)

export default IndexPage

export const indexQuery = graphql`
  {
    designs: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { type: { eq: "design" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            path
            thumbnail {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
            type
            description
            product
            company
          }
        }
      }
    }
  }
`
